<template>
  <div>
    <b-row class="my-4">
      <b-col cols="12">
        <h3 class="center text-info float-left text-uppercase">
          {{ $t('sections.' + type) + ' - ' + $t('general.create') }}
        </h3>

        <router-link :to="{ path: `/artists/${type}` }">
          <b-button size="sm" class="ml-3" variant="info">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-backspace-fill mr-2"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M15.683 3a2 2 0 0 0-2-2h-7.08a2 2 0 0 0-1.519.698L.241
                7.35a1 1 0 0 0 0 1.302l4.843 5.65A2 2 0 0 0 6.603 15h7.08a2
                2 0 0 0 2-2V3zM5.829 5.854a.5.5 0 1 1 .707-.708l2.147 2.147
                2.146-2.147a.5.5 0 1 1 .707.708L9.39 8l2.146 2.146a.5.5 0 0
                1-.707.708L8.683 8.707l-2.147 2.147a.5.5 0 0 1-.707-.708L7.976
                8 5.829 5.854z"
              />
            </svg>
            {{ $t('buttons.back') }}
          </b-button>
        </router-link>
      </b-col>
    </b-row>

    <form @submit.prevent="onSubmit" v-if="loaded" autocomplete="new-password">
      <b-row>
        <b-col lg="12" sm="12" class="mb-3 mt-3">
          <h4 class="mb-2">{{ $t('artistsManagement.info') }}</h4>
        </b-col>

        <b-col lg="7" sm="12" class="mb-3">
          <b-input-group :prepend="$t('inputs.name')" class="mb-2">
            <b-form-input
              autocomplete="new-password"
              :placeholder="$t('placeholder.name')"
              v-model="model.name"
              required
              maxlength="100"
              type="text"
            />
          </b-input-group>
          <b-input-group prepend="Email (login)" type="email" class="mb-2">
            <b-form-input
              :placeholder="$t('placeholder.email')"
              v-model="model.email"
              type="email"
              required
              maxlength="100"
              :disabled="!!model.id"
            />
          </b-input-group>

          <b-input-group :prepend="$t('inputs.password')" class="mb-2">
            <b-form-input
              autocomplete="new-password"
              :placeholder="$t('placeholder.password')"
              v-model="model.password"
              maxlength="25"
              pattern=".{6,25}"
              :title="$t('messages.invalidPasswordLength')"
              type="password"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" class="text-right">
          <b-button :disabled="loading" type="submit" variant="success">
            {{ $t('buttons.save') }}
          </b-button>
        </b-col>
      </b-row>
    </form>

    <b-row v-else>
      <b-col cols="12">
        <h5 class="text-center mt-4">{{ $t('messages.loading') }}...</h5>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import * as Sentry from '@sentry/browser';
import ArtistsManage from '../../../../services/ArtistsManage';
import Toast from '../../../../assets/js/toast';
import 'vue-datetime/dist/vue-datetime.css';

export default {
  props: ['type'],

  data() {
    const data = {
      loaded: false,
      loading: true,

      model: {
        id: '',
        name: '',
        email: '',
        password: '',
      },
    };
    return data;
  },

  watch: {
    // eslint-disable-next-line func-names
    '$route.path': function () {
      this.onLoad();
    },
  },

  beforeMount() {
    this.onLoad();
  },

  methods: {
    async onLoad() {
      this.loading = true;
      this.loading = false;
      this.loaded = true;
    },

    async onSubmit() {
      try {
        this.loading = true;
        const form = {
          ...this.model,
          type: this.type,
        };
        const artist = await ArtistsManage.save(form, form.id);
        this.$router.push({ path: `/artists/${this.type}` });
        window.open(
          `${process.env.VUE_APP_CMS_ARTISTS}/login?jwt=${artist.jwt}`,
        );
      } catch (e) {
        Sentry.withScope((scope) => {
          scope.setExtra('model', JSON.stringify(this.model, undefined, 2));
          Sentry.captureException(e);
        });
        Toast.error(this, e);
        this.loading = false;
      } finally {
        this.loading = false;
        this.model.password = '';
        this.model.name = '';
        this.model.email = '';
      }
    },
  },
  computed: {},
};
</script>

<style lang="sass" scoped>
.module
  min-width: 150px
.preview
  max-height: 225px
.v-select
  width: calc(100% - 85px)
iframe
  width: 100%
.vdatetime
  flex-grow: 1
</style>
